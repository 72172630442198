import Vue from "vue";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBEKqyF4AEvHPavCJ91RY6Ci-2tGu2xw1c",
  authDomain: "gogopower-design-company-2a160.firebaseapp.com",
  databaseURL: "https://gogopower-design-company-2a160-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "gogopower-design-company-2a160",
  storageBucket: "gogopower-design-company-2a160.appspot.com",
  messagingSenderId: "591771430013",
  appId: "1:591771430013:web:866092fceb3fc5cf215514",
  measurementId: "G-1QMV14SEM8"
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "GOGOPOWER DESIGN COMPANY LIMITED"; //公司名稱
      },
      get regFormDomain() {
        return "https://gogopowerdesign.xyz/"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}
